.projects {
  background: radial-gradient(at top left, #172657 20%, #121c33 48%);
  color: white;
}

.projects-description {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 180px 20px 50px 20px;
  background-image: url("../../../public/images/Projectsbgrn.svg");
  background-size: 520px;
  background-repeat: no-repeat;
  background-position: right;
}

.projects-title {
  font-size: 3rem;

  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
}

.projects-text {
  width:77%;
  max-width: 100%;
  margin: 0 7.5%;
}

.active-button,
.inactive-button {
  color: white;
  background: transparent;
  border: solid 1px white;
  border-radius: 70px;
  font-size: 16px;
  padding: 20px 40px;
}
.active-button {
  background: #2db46a;
}

@media screen and (max-width: 768px) {
  .projects-description {
    padding: 20% 20px 50px 20px;
  }
  .projects-title{
    font-size: 2.5rem;
    padding-left:44px;
  }
  .active-button,
  .inactive-button {
    font-size: 14px;
    padding: 15px 30px;
  }
}

@media screen and (max-width: 480px) {
  .projects-description {
    padding: 160px 20px 30px 20px;
  }
  .projects-title{
    font-size: 2.0rem;
    padding-left:20px;
  }
  .active-button,
  .inactive-button {
    font-size: 25px;
    padding: 10px 20px;
  }
}

.section-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

/* Footer.css */

.footer {
    height: 100%;
    color: white;
    
    background-image: 
                      url('../../../public/images/Footerbackground.svg'), 
                      url('../../../public/images/Footerbackground1.svg');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
  }
  
  .footer-container {
    display: flex;
    gap: 39%;
    padding-top: 115px;
    justify-content: center;
  }
  
  .footer-container img {
    padding-bottom: 220px;

  }
  
  .footer-text {
    padding-right:4%;
    text-align: end;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
  .text-slogan {
    font-size: 30px;
  }
  .text-contact a {
    text-decoration: none;
    color: inherit; 
  }
  .text-contact {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    gap: 12px;
  }
  
  .bottom-side {
    display: flex;
    padding-top: 300px;
    padding-bottom:20px;
    padding-left: 6%;
    gap:60%;
  }
  
  .toTop {
    display: flex;
    gap: 24px;
    font-size: 24px;
  }
  .square p{
    font-size:24px;
  }
  .fa-arrow-up {
    font-size: 62px;
    margin-top: -33px;
  }
  
  @media screen and (max-width: 1200px) {
    .footer-container {
      gap: 300px; 
    }
    .footer-container img {
      padding-left:5%;
    }
  }
  
  @media screen and (max-width: 992px) {
    .footer-container {
      gap: 100px; 
    }
  }
  
  @media screen and (max-width: 768px) {
    .footer {
      height: auto;
    }
  
    .footer-container {
      flex-direction: column;
      align-items: center;
      padding-top: 50px; 
      gap: 30px; 
    }
  
    .footer-container img {
      padding-bottom: 30px; 
    }
  
    .footer-text {
      text-align: center;
      gap: 20px; 
    }
  
    .text-slogan {
      font-size: 24px; 
    }
  
    .text-contact {
      font-size: 20px; 
    }
  
    .bottom-side {
      flex-direction: column;
      align-items: center;
      padding-top: 30px; 
      gap: 25px; 
    }
  
    .toTop p {
        display: none;
      }
  
      .toTop {
        justify-content: center;
        padding-top:20px;
      }
  }
  
.container__slider {
    position: relative;
    width: 532px;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: black;
}

.container__slider__links {
    position: flex;
    align-items: center;
    justify-content:center;
    padding-top:20px;
    padding-left: 40%;
    
}

.container__slider__links-small {
   position: flex;
    align-items: center;
    width: 14px;
    height: 14px;
    padding: 0;
    border-radius: 50%;
    outline: none;
    border: 0;
    background-color: white;
    
    transition: background-color 1s ease;
    margin: 0 2px;
}

.container__slider__links-small-active {
    background-color:#10C55E;
    width: 20px;
    height: 20px;
}

.slider__item {
    box-sizing: border-box;
    min-width: 100%;
    overflow: hidden;
    object-fit: cover;
    transform: translateX(0);
    transition: transform 1s ease;
}

.slider__item-active-1 {
    transform: translateX(0);
}

.slider__item-active-2 {
    transform: translateX(-100%);
}

.slider__item-active-3 {
    transform: translateX(-200%);
}

.slider__item-active-4 {
    transform: translateX(-300%);
}

.slider__item-active-5 {
    transform: translateX(-300%);
}

.slider__item-active-6 {
    transform: translateX(-300%);
}

.slider__item-active-7 {
    transform: translateX(-300%);
}

.slider__item img {
    width: 100%;
    height:500px;
}
@media (max-width: 1024px){

    .slider__item img {
        width: 100%;
        height:700px;
    }
}
@media (max-width: 600px){

    .slider__item img {
        width: 100%;
        height:300px;
    }
}
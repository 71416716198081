.services {
  background: linear-gradient(
      90deg,
      rgba(10, 15, 46, 1) 0%,
      rgba(10, 15, 46, 1) 76%,
      rgba(17, 29, 41, 1) 100%
    ),
    radial-gradient(
      circle at bottom left,
      #182746 0%,
      #182746 50%,
      #182746 100%
    );
  color: white;
}

.xlinq {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.top-side{
  width:100%;
  margin:auto;
  display:flex;
  flex-direction:column;
}
.squares {
  justify-content: center;
  display: flex;
  
  gap: 9%;
  padding-top: 55px;
  
}

.square {
  width: 90px;
  height: 103px;
  border: 3px solid #28ab5f;
  font-size: 110px;
  font-family: "Reddit Mono", monospace;
}

.wrappers {
  gap: 56px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 1200px;
}

.service-headline {
  font-size: 64px;
  color: white;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
}

.element {
  display: flex;
  gap: 35px;
}

.element-alt {
  display: flex;
  gap: 35px;
  flex-direction: row-reverse;
}

.offer {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  font-size: 60px;
  padding-top: 190px;
  margin:auto;
}

.service-text {
  font-size: 16px;
}

.element > div {
  flex-direction: column;
}

@media screen and (max-width: 1200px) {
  .offer {
    padding-left: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .element,
  .element-alt {
    flex-direction: column;
  }

  .squares {
    gap: 10px;
    flex-direction: column;
    padding-right: 15%;
    padding-top: 220px;
  }

  .square {
    width: 50px;
    height: 70px;
    font-size: 50px;
  }

  .service-headline,
  .service-text {
    text-align: center;
  }
  .service-text {
    padding: 0px 10%;
  }
  .top-side {
    display: flex;
    gap:25px;
    flex-direction:row;
  }
  .square-title {
    width: 20px;
  }
  .wrappers img {
    width: 90%;
    margin: auto;
  }
}
@media screen and (max-width: 395px){
.offer{
  font-size:40px;
}
}

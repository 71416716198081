.about-full{
    background: radial-gradient(at top left, #172657 20% , #121c33 48% , #070812 100%);
    padding-top:150px;
    
}
.about-full > .about-wrapper{
    background-image:url("../../../public/images/Projectsbgrn.svg");
    background-size: 30%;
    background-position: top 0% right 3%; 
    background-repeat: no-repeat;
}
.aboutFull-headline{
    font-size:64px;
    width:90%;

    font-family: "IBM Plex Sans", sans-serif;
    font-weight:300;
}
.aboutFull-cards{
    display:flex;
    padding-left:11%;
    flex-wrap: wrap;
}
.about-full-description{
    width:70%;
}
@media screen and (max-width: 600px) {
    .aboutFull-headline{
        font-size:30px;
        width:90%;
    
        font-family: "IBM Plex Sans", sans-serif;
        font-weight:300;
    }
    .aboutFull-cards{
        padding-left:1%;
    }
}
.zigzag {
}

.zigzag-container {
  display: flex;
  font-family: "Montserrat", sans-serif;
  flex-direction: column;
  color: #ffffff;
  padding: 0 6%;
}
.headline {
  font-size: 40px;
}
.zigzag-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 100px;
}

.zigzag-item p,
.zigzag-item img {
  flex: 1;
}

.zigzag-item:nth-child(even) {
  flex-direction: row-reverse;
}

.zigzag-item:nth-child(even) img {
  margin-left: 20px;
  margin-right: 0;
}

.zigzag-item:nth-child(odd) img {
  margin-right: 20px;
  margin-left: 0;
}
.second-container {
  display: flex;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight:300;
  position: relative;
  color: white;
}
.second-headline {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight:300;
  font-size: 64px;
  padding-top: 50px;
  padding-left: 7%;
}
.list {
  padding-left: 7%;
  line-height: 1.6;
  width:97%;
  padding-top: 80px;
  gap: 21px;
  display: flex;
  flex-direction: column;
  color: white;
}
.wrapper {
  display: flex;
  gap: 25px;
}
.descr {
  width: 400px;
}
.descr-name {
  display: flex;
  gap: 40px;
  font-size: 22px;
  padding-bottom: 21px;
  align-items: center;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight:300;
}
.numbers {
  font-size: 48px;
  opacity: 29%;
}

.focus {
  padding-bottom: 80px;
}
.third-headline {
  text-align: center;
  font-size: 64px;
  color: white;
  padding-top: 80px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight:300;
}

.elipsse {
  position: absolute;
  margin-left:430px;
  margin-top:70px;
  user-drag: none;
  height: 121px;
  width: 660px;
  background-color: transparent;
  border: solid 2px #2db46a;
  border-radius: 50%;
}
.bgrnd {
  background-image: url("../../../public/images/plata.svg");
  background-size: 600px;
  background-repeat: no-repeat;
  background-position: right bottom;
}
@media only screen and (max-width: 1024px) {
  .zigzag-container {
    padding: 0 20px; /* Adjust padding for smaller screens */
  }
  .zigzag-item {
    flex-direction: column; /* Stack items vertically on smaller screens */
    margin-bottom: 50px;
  }
  .zigzag-item:nth-child(even),
  .zigzag-item:nth-child(odd) {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }
  .zigzag-item img {
    margin: 20px auto;
    width:90%; /* Center align images */
  }
  .second-headline {
  font-size: 40px; /* Decrease font size for smaller screens */
  padding-left: 50px;
}
.list{
  padding-left:50px;
}
}
@media only screen and (max-width: 768px){
.second-headline {
  font-size: 40px; /* Decrease font size for smaller screens */
  padding-left: 20px;
}
.list {
  padding-left: 3%; /* Adjust padding for smaller screens */
  padding-top: 50px;
}
.wrapper {
  flex-direction: column;
}
.descr {
  width: 327px; /* Allow text to wrap on smaller screens */
}
.descr-name {
  font-size: 22px; /* Decrease font size for smaller screens */
  padding-bottom: 10px;
}
.numbers {
  font-size: 36px; /* Decrease font size for smaller screens */
}
.third-headline {
  font-size: 40px; /* Decrease font size for smaller screens */
  padding-top: 50px;
  width:220px;
  padding-left:50px;
  
}

.bgrnd {
  background-image: url("../../../public/images/plata.svg");
  background-size: 400px;
  background-repeat: no-repeat;
  background-position: right bottom;
}
.elipsse {
  position: absolute;
  margin-left:40px;
  margin-top:95px;
  user-drag: none;
  height: 56px;
  width: 196px;
  background-color: transparent;
  border: solid 2px #2db46a;
  border-radius: 50%;
}}
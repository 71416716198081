.hero-container {
  background: url("../../../public/images/Rectangle 749.svg")
    center center/cover no-repeat;
 
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  box-shadow: inset 0 0 0 1000px rgba(0, 4, 25, 0.5);
  object-fit: contain;
}
.hero .ab{
  font-size:28px;
}
.welcome {
  font-size: 71px;
  color: #fff;
  font-family: "IBM Plex Sans", sans-serif;
}
.real {
  color: #10c55e;
  font-weight: bold;
}
.hero-btns {
  margin-top: 56px;
}
.custom-btns {
  border-color: #10c55e;
}
.jj {
  margin-left: 7%;
  margin-top: 300px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .welcome {
    font-size: 45px;
    color: #fff;
  }
  
  .hero-btns {
    margin-top: 20px;
  }
  .btn-mobile {
    display: block;
    text-decoration: none;
  }
  .jj{
    margin:15px;
    margin-top: 100px;
  }
 

}

.esim{
  
  display:flex;
  padding-top:20px;
}

.second {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content vertically center */
}
.brain {
  position:relative;
  height: 664px;
  width: 580px;
  left:10%;
}

.paragraph {
  position:relative;
  padding-left:10%;
  color: white;
  width: 70%;
  text-align: start;
}

.pictures {
        display: grid;
        grid-template-columns: repeat(5); /* 3 columns */
        grid-template-rows:repeat(5);
    }

    .layout {
        grid-column: 3; /* Place in first column */
        grid-row: 1; /* Place in first row */
        animation:floatUp 2s infinite ease-in-out;
    }

    .fpga {
        grid-column: 3; /* Place in second column */
        grid-row: 3; /* Place in first row */
        animation:floatUp 2s infinite ease-in-out;
    }

    .electronics {
        grid-column: 1 / 2; /* Place in third column */
        grid-row: 5 / 3; /* Place in first row */
        animation:floatUp 2s infinite ease-in-out;
    }

    .simulations {
      grid-column: 1 / 4; /* Place in third column */
      grid-row: 1 / 2; /* Place in second row */
      animation:floatUp 2s infinite ease-in-out;
    }

    .enclosure {
        grid-column: 2 / 3; /* Place in second column */
        grid-row: 2 / 3;
        animation: floatUpDown 2s infinite ease-in-out;
         /* Place in second row */
    }
 
    
    @keyframes floatUpDown {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-15px); 
        

      }
      100% {
        transform: translateY(0);
      }
    }
    @keyframes floatUp {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(15px); 
        

      }
      100% {
        transform: translateY(0);
      }
    }
    @media only screen and (max-width: 1024px) {
      .brain {
        display: none; 
      }
      .esim{
  
        display:flex;
        padding-top:20px;
        flex-direction:column;
      }
      .paragraph {
        padding: 0 10px;
        width:90%; 
        padding-left:20px;
        font-size:18px;
      }
    
      .pictures {
        grid-template-columns: repeat(1); 
      }
      .layout {
        grid-column: 3; /* Place in first column */
        grid-row: 1; /* Place in first row */
        animation:none;
    }

    .fpga {
        grid-column: 3; /* Place in second column */
        grid-row: 3; /* Place in first row */
        animation:none;
    }

    .electronics {
        grid-column: 2 / 2; /* Place in third column */
        grid-row: 2 / 3; /* Place in first row */
        animation:none;
    }

    .simulations {
      grid-column: 1 / 4; /* Place in third column */
      grid-row: 1 / 2; /* Place in second row */
      animation:none;
    }

    .enclosure {
      grid-column: 3; /* Place in second column */
      grid-row: 2;
        animation:none;
    }
    }
   @media only screen and  (max-width:820px){
    .brain{
      display:none;
    }
    .esim{
      flex-direction:column;
    }
    
    .layout {
      grid-column: 3; /* Place in first column */
      grid-row: 1; /* Place in first row */
      animation:none;
  }

  .fpga {
      grid-column: 3; /* Place in second column */
      grid-row: 3; /* Place in first row */
      animation:none;
  }

  .electronics {
      grid-column: 2 / 3; /* Place in third column */
      grid-row: 5 / 3; /* Place in first row */
      animation:none;
  }

  .simulations {
    grid-column: 1 / 4; /* Place in third column */
    grid-row: 1 / 2; /* Place in second row */
    animation:none;
    width:20%;
  }

  .enclosure {
   display:none;
      }   }
  

@media only screen and (max-width: 480px) {
  /* Adjust styles for even smaller screens */
  
  .layout{
    width:90%;
  }
  .simulations{
    width:50%;
  }
 
  
}
.about {
  display: flex;
  color: white;
  flex-direction: column;
  padding-left:7%;
  gap:20px;
  
}
.about > .headline {
    padding-bottom:30px;
}
.about-description {
  width: 780px;
}
li {
    line-height:33px;
    
}
.lili{
  margin-left:5%;
}
.about-wrapper{
   
    display: flex;
    gap:40px;
    padding-bottom:50px;
}
.about-container {
    padding: 2px 16px;
  }
.about-cards{
    display:flex;
    position:relative;
    padding-top:310px;
    right:220px;
    gap:15px;
}
.about-card{
    color:white;
    border-inline-start:2px solid ;
    border-block-start:2px solid ;
    border-image-source:radial-gradient(circle at top left,white,transparent 50%);
    border-image-slice:1;
    padding:8px;
}
.description-container {
    width:70%;
}
.about-headline{
  font-size:64px;
}
.card-name{
    display:flex;
    gap:125px;
    font-size:22px;
}
.fab.fa-linkedin {
    font-size: 36px; 
  }
  .about-button {
    position: relative; 
    background-color: transparent;
    border: solid 1px #10C55E;
    width: 270px;
    color: white;
    padding: 10px 80px 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    cursor: pointer;
    border-radius: 0px;
    transition: background-color 0.3s;
}

.about-button::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 53px; 
    width: 0.5px;
    background-color: #10C55E;
}
.about-button:hover::after{
  background-color:white;
}

.about-button::before {
  content: "\f054"; 
  font-family: "Font Awesome 5 Free"; 
  font-weight: 900;
  font-style: normal; 
  position: absolute;
  top: 50%;
  right: 20px; 
  transform: translateY(-50%); 
}

@media only screen and (max-width: 1024px) {
  .about-wrapper {
    flex-direction: column;
    padding-bottom: 20px;
    padding-left:2%;
    width:95%;
  }
  .lili{
    margin-left:5%;
  }
  .about-cards {
    padding-top: 20px;
    right: 0;
    border-inline-start:2px solid ;
    border-block-start:2px solid ;
    border-image-source:radial-gradient(circle at top left,white,transparent 20%);
    border-image-slice:1;
    padding:4px;
    flex-direction:column;
    margin:auto;
  }
  .card-image{
    width:98%;
  }
  .about-card{
    border:none;
  }
  .about {
    padding-left: 20px;
  }

  .about-headline {
    font-size: 40px;
  }

  .about-description {
    width: auto;
  }

  .description-container {
    width: 80%;
  }

  .card-name {
    gap: 20px;
    font-size: 18px;
  }

  .fab.fa-linkedin {
    font-size: 24px;
  }

}
.fourthcontainer {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  color: white;
  font-family: "Montserrat", sans-serif;
  gap: 40px;
  padding: 70px 0;
  background-image: url("../../../public/images/Footerbackground.svg"),
    url("../../../public/images/Footerbackground1.svg");
}

.title {
  padding-left: 7%;
  font-size: 64px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
}

.mission {
  position: relative;
  width: 550px;
  margin-left: 55%;
}

@media screen and (max-width: 1600px) {
  .title {
    font-size: 60px;
  }
}

@media screen and (max-width: 1400px) {
  .title {
    font-size: 55px;
  }
}

@media screen and (max-width: 1200px) {
  .title {
    font-size: 50px;
  }
}

@media screen and (max-width: 992px) {
  .title {
    font-size: 45px;
  }
}

@media screen and (max-width: 1024px) {
  .fourthcontainer {
    padding: 50px 0;
  }

  .title {
    padding-left: 20px;
    font-size: 40px;
  }

  .mission {
    width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 32px;
  }

  .mission {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (max-width: 480px) {
  .title {
    font-size: 28px;
  }

  .mission {
    width: 90%;
    margin:auto;
  }
}

@media screen and (max-width: 360px) {
  .title {
    font-size: 24px;
  }
}
@media screen and (max-width: 1024px) {
  .title {
    font-size: 42px;
  }
}

@media screen and (max-width: 834px) {
  .title {
    font-size: 36px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 32px;
  }
}

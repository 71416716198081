.header {
  background: #030010;
  overflow: hidden;
  height: 80px;
  position: relative;
}

.header div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 70px;
  transform: translate(100%, 0);
}

.header p {
  color: white;
  font-size: 48px;
  text-transform: uppercase;
}

@keyframes ticker {
  0% {
    transform: translate(100%, 0);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

.ticker-wrapper__first-half,
.ticker-wrapper__second-half {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  animation: ticker 30s infinite linear forwards;
}

.ticker-wrapper__second-half {
  animation: ticker 30s infinite linear forwards;
  animation-delay: 15s; /* Delay the animation for the second half */
}

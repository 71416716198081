.touch-container {
    

}
.touch {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 520px;
  color: white;
  padding-left: 120px;
  padding-top: 10px;
  gap: 30px;
  line-height: 1.6;
}
.touch-headline {
  font-size: 60px;
  color: #2db46a;
  font-weight: bold;
}
.touch .fab.fa-linkedin {
  font-size: 45px; /* Adjust the size as needed */
}
.touch .fab.fa-facebook-f {
  font-size: 45px; /* Adjust the size as needed */
}

.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.links{
  display:flex;
  gap:40px;
}
label {
  display: block;
}

input[type="text"],
input[type="email"],
input[type="tel"] ,
#message
{ color:white;
  width: 100%;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  margin-bottom:18px;
  background: rgba(0, 0, 0, 0.1);
}
form {
  color: white;
  width: 548px;
  border: solid 1px transparent;
  border-radius: 8px;
  padding: 40px 24px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px 0 #7A7878;
}
button {
  padding: 10px 20px;
  padding-right: 120px;
  font-size: 28px;
  background-color: transparent;
  color: #fff;
  border: solid 1px #10c55e;
  cursor: pointer;
  transition: 0.3s;
}

button:hover {
  background-color: #10c55e;
  border: solid 1px white;
}
.form {
  padding-top: 170px;
  padding-left: 120px;

}
.submit-button{
    margin-top:90px;
    margin-left:250px;
}
input::placeholder{
    padding-left:5px;
    color:white;
}
textarea{
    resize: none;
}
textarea::placeholder{
    padding-left:5px;
    color:white;
}
.touch-container .wrapper {
  padding-bottom:10px;
}
@media only screen and (max-width: 768px){
  .wrapper{
    flex-direction:column;
  }
  .touch {
    width:450px;
    margin:auto;
    display: flex;
    flex-direction: column;
    position: relative;
    color: white;
    padding-left:0;
    padding-top: 10px;
    gap: 10px;
    line-height: 1.6;
  }
  .touch-headline {
    font-size: 41px;
    
  }
  form{
    width:400px;
  }
  .form {
    padding:40px 
  }
  .submit-button{
    margin-top:0px;
    margin-left:100px;
    padding:10px 20px;
}
@media only screen and (max-width: 520px) {
  .touch {
    width: 90%; 
    padding-left: 20px; 
  }
  .touch-headline {
    font-size: 30px; 
  }
}

@media only screen and (max-width: 400px) {
  .touch {
    width: 90%; 
    padding-left: 10px; 
  }
  .touch-headline {
    font-size: 25px; 
  }
  form {
    width: 327px; 
    padding: 20px; 
  }
  .submit-button {
    margin-top: 20px; 
    margin-left: 0; 
    padding: 10px 20px; 
  }
}
}

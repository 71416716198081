.carousel-page {
  overflow-x: hidden;
  background: radial-gradient(
    circle at -55vw 110vh,
    rgba(28, 62, 241, 1) 0%,
    rgba(92, 150, 237, 1) 47%,
    rgba(229, 247, 234, 1) 100%
  );
}

.carousel-headline {
  margin: 0px auto;
  font-size: 4rem;
  display: table;
}

.carousel-container {
  padding-top: 20px;
  width: 1212px;
  height: 684px;
  display: flex;
  gap: 5px;
  transition: transform 0.5s ease;
}

.carousel-card {
  width: 300px;
  height: 684px;
}

.card-description {
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  height: 190px;
  padding-top: 10px;
  padding-left: 8px;
  color: white;
}

.carousel-card {
  position: relative;
}
.link-style {
  text-decoration: none;
  color: inherit;
}
.card-type {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px 8px;
  border-radius: 20px;
  color: white;
  font-size: 12px;
}

.arrow-button {
  padding: 0;
  cursor: pointer;

  transition: background-color 0.3s ease;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.6), 0 2px 9px rgba(0, 0, 0, 0.3) inset;
}

.arrow-button i {
  font-size: 28px;
  font-weight: 550;
  color: white;
}

.carousel-buttons {
  display: flex;
  gap: 10px;
  position: relative;
  justify-content: center;
  bottom: 80px;
}
.carousel-card img {
  width: 300px;
  height: 439px;
  object-fit: cover;
  object-position: center;
}
.carousel-card.active {
  transform: translateX(0);
}
.overflowTest {
  position: relative;
  margin: auto;
  width: 88%;
  color: white;
  padding: 15px;
  height: 640px;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-mask-image: linear-gradient(
    to right,
    transparent,
    black 10%,
    black 90%,
    transparent
  );
  mask-image: linear-gradient(
    to right,
    transparent,
    black 1%,
    black 94%,
    transparent
  );
}
.fa-arrow-right {
  background-color: black;
  background: #10c55e;
  padding: 15px;
}
.fa-arrow-left {
  background-color: black;
  background: black;
  padding: 15px;
}
@media (max-width: 768px) {
  .carousel-page {
    color: white;
    background: linear-gradient(to bottom, #0d131a 100%, #142134 100%);
  }
  .carousel-card img {
    height: 300px;
  }
  .card-description {
    height: 150px;
  }
  .carousel-headline {
    padding-left: 0;
    text-align: center;
    font-size: 2.625rem;
  }
  .overflowTest {
    left: 0;
    width: 100%;
  }
  .carousel-container {
    gap: 4px;
  }

  .carousel-card {
    width: 100%;
  }
  .overflowTest {
    height: 530px;
  }
  .carousel-buttons {
    display: none;
  }

  .arrow-button.right {
    display: none;
  }

  .arrow-button.left {
    display: none;
  }
}

.singlepic{
    width:80%;
    padding-left:20%;
    padding-top:40px;
}
@media screen and (max-width: 1024px){
    .singlepic{
        width:100%;
        padding-left:0px;
    padding-top:40px;
    }
}
.product {
  color: white;
}
.product-page {
  background: #090d25;
  background: radial-gradient(at bottom left, #172657 20%, #121c33 48%);
  background: radial-gradient(at top right, #172657 20%, #121c33 48%);
}
.product-content {
  display: flex;
  gap:40px;
  background-image: url("../../../public/images/telegrampic.svg");
  background-size: 267px;
  background-repeat: no-repeat;
  background-position: 40% 800px;
}
.product-name {
  padding-top: 15%;
  padding-left: 16.5%;
  width:80%;
  font-size: 8dvh;
}
.img-slide {
  padding-top: 180px;
  margin-right:6%;
}
.product-description {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.product-text {
  padding-left: 16.5%;
  line-height: 30px;
  width:85%;
}
.category {
  padding-left: 120px;
  display: flex;
  gap: 8px;
}
.category p {
  font-size: 16px;
  padding: 6px;
}
.category-item {
  border: solid 1px #10c55e;

  border-radius: 20px;
}
.photoArray {
  padding: 30px 120px;
  display: grid;
  grid-gap: 0.5em;
  grid-template-areas:
    "photoOne photoTwo photoThree "
    "photoOne photoFour photoFive "
    
    ;
  grid-template-columns: 4fr 14% 11% ;
}

.photoArray > div {
  border: 1px solid transparent;
  border-radius: 0px;
  display: flex;
  justify-content: center; 
  align-items: center; 
  overflow: hidden; 
}

.photoArray > div:first-child {
  grid-area: photoOne;
  height: 500px;
}

.photoArray img {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
}
@media (max-width: 1366px){
  .product-content{
    flex-direction:column;
  }
  .img-slide {
    padding-top: 40px;
    padding-left: 5%;
  }
  .product-name {
    padding-top: 20%;
    padding-left: 16.5%;
    width:80%;
    font-size: 5dvh;
  }
    .photoArray {
      padding: 10px; 
      grid-template-columns: 1fr; 
      grid-template-areas:
        "photoOne"
        "photoTwo"
        "photoThree";
    }
  
    .photoArray > div {
      margin-bottom: 10px; 
    }
  
    .photoArray > div:first-child {
      height: auto; 
    }
    .container__slider{
      width:99%;
    }
  
}
@media (max-width: 786px){
  .product-name {
    padding-top: 170px;
    padding-left: 16.5%;
    width:80%;
    font-size: 5dvh;
  }
}
.navbar {
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.navbar.scrolled {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 70%,
    rgba(0, 0, 0, 0) 100%
  );
}

.navbar .nav-menu .nav-links:hover {
  color: #10c55e; /* Your desired hover color */
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  justify-self: start;
  cursor: pointer;
  transform: translate(-45%, 4%);
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 30px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  position: relative;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.random-class {
  position: relative;
  display: block;
  background: transparent;
  width: 200px;
  line-height: 56px;
  text-align: center;
  font-size: 20px;
  text-decoration: none;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
}
.requestQuote {
  transform: translateY(-23%);
}
.random-class:before,
.random-class:after {
  position: absolute;
  content: "";
  width: 200px;
  left: 0px;
  height: 34px;
  z-index: -1;
}

.random-class:before {
  transform: perspective(200px) rotateX(60deg);
}

.random-class:after {
  top: 19px;
  transform: perspective(200px) rotateX(-60deg);
}

.random-class.border:before,
.random-class.border:after {
  border: 3px solid white;
}

.random-class.border:before {
  border-bottom: none; /* to prevent the border-line showing up in the middle of the shape */
}

.random-class.border:after {
  border-top: none; /* to prevent the border-line showing up in the middle of the shape */
}

.random-class.border:hover:before,
.random-class.border:hover:after {
  background: white;
}

.random-class.border:hover {
  color: black;
}

/* New CSS for active link underline with arrow */
.nav-links.active {
  position: relative;
  color: #10c55e;
}

.nav-links.active::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;

  border-left: 80px solid transparent;
  border-bottom: 5px solid #10c55e;
}

@media screen and (max-width: 960px) {
  .navbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 0px;
    left: -100%;
    opacity: 0.7;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #000000;
    left: 0;
    opacity: 0.9;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .navbar-button {
    /* Add your styles for the button in smaller media here */
    padding: 14px 20px; /* Example padding */
    font-size: 1.5rem; /* Example font size */
  }
  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 3%;
    transform: translate(25%, 50%);
    z-index: 900;
  }

  .menu-icon {
    display: block;
    position: absolute;
    z-index: 213;
    top: 0;
    right: 0;
    transform: translate(-80%, 60%);
    font-size: 3.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
  }
}
@media screen and (max-width: 1024px){
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 3%;
    transform: translate(10%, 30%);
    z-index: 900;
  }
}
.zzgzg-section {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width:95%;
  margin:auto;
}

.zzgzg-section > * {
  margin-left: auto;
  margin-right: auto;
}

.zzgzg-text {
  width: 1000px;
}

.zzgzg-image img {
  max-width: 400px;
}

.zzgzg-headline {
  font-size: 40px;
  color: #2db46a;
  width: 586px;
}

.zzgzg-item {
  display: flex;
  gap: 15px;
  text-decoration: none; /* Remove link decoration */
  color: inherit; /* Inherit color from parent */
}

.zzgzg-content {
  display: flex;
  flex-direction: column;
}

/* Add styles for reversed items */
.zzgzg-item.reversed .zzgzg-content {
  order: 2; /* Change the order of content */
}

.zzgzg-item.reversed .zzgzg-image {
  order: 1; /* Change the order of image */
}
@media (max-width: 1024px) {
  .zzgzg-headline {
    font-size: 32px;
    width: 70%; /* Adjust font size for smaller screens */
  }

  .zzgzg-text {
    width: 80%; /* Set text width to full width for smaller screens */
    max-width: none; /* Remove maximum width for smaller screens */
  }

  .zzgzg-image img {
    max-width: 80%; /* Set image width to 100% for smaller screens */
    height: auto;
    padding-left:10%;
     /* Maintain aspect ratio */
  }

  .zzgzg-item {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .zzgzg-item.reversed {
    flex-direction: column-reverse; /* Stack items vertically and reverse order on smaller screens */
  }
  .zzgzg-content {
    align-items: center;
  }
}
